import React from 'react';
import logo from '../../assets/svg/logo_01.svg';
import './Oops.css';

const Oops = () =>(
    <div className="Oops">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Página em Construção</h1>
    </div>
);

export default Oops;
