import React from 'react';
import { Checkbox, Table } from 'rbx';
import './Lista.css';

class Lista extends React.Component {
    state = {
        data: this.props.valores
    }

    render() {
        var exames = this.state.data;
        var cabecalho = exames.cabecalho.map((e)=><Table.Heading>{e}</Table.Heading>);
        var linhas = exames.linhas.map((e)=>{
            return(
                <Table.Row>
                <Table.Cell><Checkbox /></Table.Cell>
                    <Table.Cell>{e.exame}</Table.Cell>
                    <Table.Cell>{e.medico}</Table.Cell>
                </Table.Row>
            );
        });

        return (
            <div className="list">
            <Table>
            <Table.Head>
                <Table.Row>
                {cabecalho}
                </Table.Row>
            </Table.Head>
            <Table.Body>
                {linhas}
            </Table.Body>
            </Table>
            </div>
        );
    };
}

export default Lista;