import React from 'react';
import Tool from '../../components/Tool';
import Lista from '../../components/Lista';
import dados from '../../dados.json';

const background = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
};

class Exames extends React.Component {
    render() {
        return (
            <div style={background}>
                <Tool />
                Exames
                <Lista valores={dados.exames}/>
            </div>
        );
    }
}

export default Exames;